import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { useDevice } from '../../../hooks/useResponsive';

function Texts() {
  const isMobile = useDevice()?.isMobile;
  return (
    <div style={{ marginLeft: 1, marginRight: 1 }}>
      <Grid container>
        <Grid item md={6} lg={6} sm={12} sx={{ padding: '10px', px: 4 }}>
          <Typography className="home-page-mal-content-p1 " variant="body1">
            പുതിയ സംരംഭകർക്ക് ഇൻകോർപ്പറേഷൻ, ഫണ്ട് റൈസിംഗ് മുതൽ Internal Administration Module, ബ്രാൻഡിംഗ്, മാർക്കറ്റിങ്,
            ഉൾപ്പടെ കൃത്യമായി
            <br /> ഗൈഡൻസ് നൽകുകയും ഇൻവെസ്റ്റ്മെന്റ് കൺസൾട്ടൻസി, ഫീസിബിലിറ്റി
            <br /> സ്റ്റഡി എന്നീ സർവീസുകൾ വരെ ചെയ്തുകൊടുക്കുകയും
            <br />
            ,സാധാരണക്കാർക്ക് മാസ ശമ്പളത്തിൽനിന്നു ഒരു സംഖ്യ
            <br /> ഇൻവെസ്റ്റ് ചെയ്തു ഒരു വരുമാനം ഉണ്ടാക്കാൻ അവസരം നൽകുകയും
            <br /> ചെയ്യുന്ന ഒരു കൂട്ടായ സംവിധാനമാണ് IB ക്ലബ്.
          </Typography>
          <Stack sx={{ py: 3 }}>
            <Typography variant="body1" className="home-page-mal-content-p2">
              ചെറുതെങ്കിലും സുരക്ഷിതവും ലാഭകരവുമായ ഒരു നിക്ഷേപമുണ്ടാകുക
              <br /> എന്നത് ഏതൊരാളുടെയും സ്വപ്നമാണ്. വരുമാനത്തിന്റെ ധാർമികത
              <br /> ഇല്ലായ്മയും നിക്ഷേപത്തിന്റെ സുരക്ഷിതത്വം ഇല്ലായ്മയും പലപ്പോഴും
              <br /> നിക്ഷേപ പദ്ധതികളിൽ നിന്ന് നമ്മെ അകറ്റി നിർത്തുന്നു.
            </Typography>
          </Stack>
          <Typography variant="body1" sx={{ pt: '' }} className="home-page-mal-content-p1">
            IB ക്ലബ് നിങ്ങളുടെ നിക്ഷേപങ്ങളെ സ്വീകരിച്ച് ധർമബോധത്തിന്
            <br /> വിരുദ്ധമാകാതെ ലാഭനഷ്ടങ്ങളിൽ തുല്യ പങ്കാളിയാക്കുന്ന <br /> സംവിധാനമാണ്. ചെറിയ തുകകൾ നിക്ഷേപിച്ച് വലിയ
            ബിസിനസ് <br />
            പ്രൊജക്ടുകളുടെ ഭാഗമാകാനുള്ള അവസരം IB ക്ലബ് ഒരുക്കുന്നു.
          </Typography>
        </Grid>
        <Grid item md={6} lg={6} sm={12} sx={{ padding: '10px', px: 4 }}>
          <Typography className="home-page-mal-content-p1" variant="body1">
            സ്വന്തമായി ബിസിനസുകൾ സ്ഥാപിച്ച് നടത്തുന്നതിന് പുറമെ മീഡിയം റിസ്കിൽ റൺ ചെയ്യുന്ന ബിസിനസുകളെ ഫണ്ടും
            കൺസൾട്ടൻസിയും നൽകി പ്രൊമോട്ട് ചെയ്യുക കൂടി ചെയ്യുന്ന ഒരു എൽ എൽ പിയാണ് IB ക്ലബ്. മറ്റു ബിസിനസുകളെ
            ഏറ്റെടുക്കുന്നതിലുപരി സ്വന്തമായി ബിസിനസുകൾ സ്ഥാപിച്ച് നടത്തുന്നതിന് തന്നെയായിരിക്കും IB ക്ലബ്ബിന്റെ പ്രഥമ
            പരിഗണന.
          </Typography>
          <Stack sx={{ py: 3 }}>
            <Typography variant="body1" className="home-page-mal-content-p2">
              നിക്ഷേപിച്ച തുകയ്ക്ക് ബിസിനസിൽ നിന്ന് ആറ് മാസത്തിനകം റിട്ടേൺ ( <br /> റിട്ടേൺ ഓൺ ഇൻവെസ്റ്റ്മെന്റ്) കിട്ടി
              തുടങ്ങുന്ന സ്വഭാവത്തിലുള്ള <br />
              ബിസിനസുകൾ മാത്രമേ ഐഡിയൽ ഫിൻസർവ് ഏറ്റെടുക്കുകയുള്ളൂ.
              <br /> നിക്ഷേപിച്ച തുക ചുരുങ്ങിയത് മൂന്ന് വർഷത്തിനുള്ളിലെങ്കിലും
              <br /> ലാഭമിനത്തിൽ കിട്ടിയിരിക്കണമെന്നതും ( റിട്ടേൺ ഓഫ് ഇൻവെസ്റ്റ്മെന്റ്) IB
              <br /> ക്ലബ് ഒരു ബിസിനസിനെ പ്രൊമോട്ട് ചെയ്യണമെങ്കിലുള്ള പരിഗണനയാണ്. <br />
              ഇത്തരം നിബന്ധനകൾ വയ്ക്കുന്നതിലൂടെ IB ക്ലബ് ൽ നിക്ഷേപം
              <br /> നടത്തുന്നവർക്ക് വേഗത്തിൽ തന്നെ ലാഭവിഹിതം ലഭിക്കുന്നു എന്ന്
              <br />
              ഉറപ്പുവരുത്താനാകും.
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Stack sx={{ py: 5 }}>
        {isMobile ? (
          <img src={'/assets/images/common/about-banner-mobile.png'} alt="" style={{ objectFit: 'contain' }} />
        ) : (
          <img src={'/assets/images/common/about-banner.jpg'} alt="" style={{ objectFit: 'contain' }} />
        )}
      </Stack>
    </div>
  );
}

export default Texts;
