export const specificationsPoints = [
  {
    id: 'U31904KL2022PTC0762184',
    name: 'KHAF SMART MAHALL',
    designation: 'Mahall Management System',
    img: '/assets/images/Projects/khaff.png',
    imgMobile: '/assets/images/Projects/khaff.png',
    text: 'എല്ലാം സ്മാർട്ടായ ഈ കാലത്ത് മഹല്ല് മാനേജ്‌മെന്റും പള്ളിയും മദ്രസ്സയും ഉൾപ്പെടെ നിക്കാഹ്, ജനനം, വരിസംഖ്യാ, തുടങ്ങി എല്ലാം ഭംഗിയായി കൈകാര്യം ചെയ്യാൻ കഴിയുന്ന സ്മാർട്ട് മഹല്ല് സോഫ്റ്റ്‌വെയർ ആണ് കാഫ് സമാർട് മഹല്ല്. ',
    detailing:
      'എല്ലാം സ്മാർട്ടായ ഈ കാലത്ത് മഹല്ല് മാനേജ്‌മെന്റും പള്ളിയും മദ്രസ്സയും ഉൾപ്പെടെ നിക്കാഹ്, ജനനം, വരിസംഖ്യാ, തുടങ്ങി എല്ലാം ഭംഗിയായി കൈകാര്യം ചെയ്യാൻ കഴിയുന്ന സ്മാർട്ട് മഹല്ല് സോഫ്റ്റ്‌വെയർ ആണ് കാഫ് സമാർട് മഹല്ല്. നമ്മുടെ സോഫ്റ്റ്‌വെയർ ഡെവലപ്മെന്റ് വിഭാഗമായ ഓസ്‌പെർബും ഐഡിയൽ ഫിൻസർവും ചേർന്ന് ഒരുക്കുന്ന ഈ പദ്ധതി കുറഞ്ഞ ചിലവിൽ ഓരോ മഹല്ലിനെയും സ്മാർട്ടാക്കാനും മഹല്ലിലെ ഓരോ പ്രവർത്തകനും നേരിട്ട് വിവരങ്ങൾ എത്തിക്കാനും കഴിയുന്ന തരത്തിൽ ഈ സംവിധാനം സജീവമാണ്. ',
    videoId: 'eqy2MpY2soc',
    Extra: [
      {
        head: '01 / LOCKING PERIOD',
        texts:
          'ഒരു വർഷം ആണ് ലോക്കിങ് പിരീഡ്, ലോക്കിങ് പിരീഡിന് മുന്നേ ഈ MXT വിൽക്കരുത്, ലോക്കിങ് പിരീഡ് ആരംഭിക്കുന്നത് ലിസ്റ്റ് ചെയ്ത ദിവസം മുതൽ ആണ് (02/08/2022)        ',
      },
      {
        head: '02 / WITHDRAWAL',
        texts: 'പിൻവലിക്കുമ്പോൾ (MXT വിൽക്കുമ്പോൾ) പ്രോസസിംഗ് ടൈം 3 ആഴ്ചകൾ ആയിരിക്കും.',
      },
      {
        head: '03 / PROFIT DIVIDING',
        texts:
          '3 മാസം കൂടി കഴിയുമ്പോഴാണ് ഇത് ഇൻകം ജനറേറ്റ് ചെയ്തു തുടങ്ങൂ. മാസം കൂടുമ്പോഴാണ് ഈ കമ്പനിയുടെ ലാഭ വിതരണം നടക്കുന്നത്. അതനുസരിച്ചായിരിക്കും ഈ MXT യുടെയും പ്രോഫിറ്റ് വിതരണം ഉണ്ടായിരിക്കുക.',
      },
      {
        head: '04 / DIVIDENT RADIO',
        texts:
          'വരുമാനത്തിന്റെ 25 ശതമാനം MXT ക്കും, 25% ഡെവലപ്പേഴ്സിനും, 25% ഐഡിയൽ മാനേജ്‌മെന്റിനും, 25% R&D ക്കും എന്ന രീതിയിലാണ് ഡിവിഡന്റ് ചെയ്യുന്നത്.',
      },
    ],
  },

  {
    id: 'U31904KL2022PTC0762182',
    name: 'Thunder Tech Energies LLP',
    img: '/assets/images/Projects/thunder.png',
    imgMobile: '/assets/images/Projects/thunder.png',
    designation: 'Power Electronics Products Manufacturing Company ',
    text: 'റിനീവെബില്‍ സൌരശക്തി ഉത്പാദനവും വൈദ്യുതി ശേഖരണവും എന്നിങ്ങനെ പ്രകൃതിയില്‍നിന്ന് വരുന്ന എനര്‍ജിയുടെ ക്ഷേത്രത്തിലും, ബെറ്ററി ചാര്‍ജിംഗും എന്നിവയിലും നിന്നുള്ള സമ്പൂര്‍ണ്ണമായ നിറവുകൽ ആണ്.',
    detailing:
      'ThunderTech എന്നത് മഞ്ചേരി ആസ്ഥാനമായി പ്രവർത്തിക്കുന്ന ഒരു ടെക്നോളജി കമ്പനി ആണ്. പ്രകൃതിയില്നിന്നുള്ള റിനീവെബ്ൾ എനർജ്ജി (സോളാർ അഥവാ സൗരോർജ്ജം) പോലെ വൈദുതി ഉത്പാദന രംഗത്തും, വൈദ്യുതി ശേഖരണം (ബേറ്ററി ചാർജ്ജിങ്) രംഗത്തും അതിനൂതനമായ  കണ്ടെത്തെലുകൾ നടത്തിയ കേരളത്തിൽ നിന്നുള്ള നമ്മുടെ സ്വന്തം ബ്രാന്റ് ആണ് ഇത്. ആറ് കോടി രൂപയുടെ പ്രസ്തു പദ്ധതിയിൽ ഭാഗവാക്കാകാനും പദ്ധതിയുടെ കസ്റ്റമർ നെറ്റവർക്ക് വളർത്തുന്നതിനും നിങ്ങളുടെ സഹകരണം ക്ഷണിക്കുന്നു      ',
    videoId: 'eqy2MpY2soc',
    Extra: [
      {
        head: '01 / LOCKING PERIOD',
        texts:
          'രണ്ടു വർഷം ആണ് ലോക്കിങ് പിരീഡ്, ലോക്കിങ് പിരീഡിന് മുന്നേ ഈ MXT വിൽക്കരുത്, ലോക്കിങ് പിരീഡ് ആരംഭിക്കുന്നത് ലിസ്റ്റ് ചെയ്ത ദിവസം മുതൽ ആണ് (09/01/2023) അവസാനിക്കുന്നത് (09/01/2025) ',
      },
      {
        head: '02 / WITHDRAWAL',
        texts: 'പിൻവലിക്കുമ്പോൾ (MXT വിൽക്കുമ്പോൾ) പ്രോസസിംഗ് ടൈം 4 ആഴ്ചകൾ ആയിരിക്കും.',
      },
      {
        head: '03 / PROFIT DIVIDING',
        texts:
          'മൂന്ന് മാസത്തിനു ശേഷം അതായത് (01/12/2023) തിയ്യതി മുതലാണ് ലാഭം പ്രതീക്ഷിക്കുന്നത്. ThunderTech എന്ന കമ്പനിക്ക് ഓരോ പ്രോഡക്റ്റിലും ലഭിക്കുന്ന മാർജിൻ ആണ് ഇതിന്റെ ലാഭം.           ',
      },
      {
        head: '04 / DIVIDENT RADIO',
        texts:
          'ലഭിക്കുന്ന മാർജിൻനിന്നു വരുന്ന വരുമാനത്തിന്റെ 90% ആണ് MXT ക്ക് ഉണ്ടാവുക 10% ഐഡിയൽ ഫിൻസെർവ് മാനേജ്‌മെന്റിന് ഉള്ളതാണ്          ',
      },
    ],
  },
  {
    id: 'U31904KL2022PTC0762183',
    name: 'V-One Natural Products',
    designation: 'Natural Product Manufacturer',
    img: '/assets/images/Projects/vone.png',
    imgMobile: '/assets/images/Projects/vone.png',
    text: 'ഒട്ടനവധി ആളുകളുടെ അനുഭവ സാക്ഷ്യം കൂടി ആയപ്പോൾ അതിന്റെ വിതരണ അവകാശം പൂർണ്ണമായും നമുക്ക് തന്നുകൊണ്ട് ഉണ്ടാക്കിയ കമ്പനിയാണ് വി-വൺ. ആഷ്‌ലിയും ഐഡിയൽ ഫിൻസെർവും കൂടിച്ചേർന്ന സംരംഭമാണ് ഇത്.',

    detailing:
      'കോഴിക്കോട് ഓമശ്ശേരിയിൽ ഉള്ള ഒരു പാരമ്പര്യ വൈദ്യരാണ് പുരുഷോത്തമൻ. അദ്ദേഹത്തിന് പൈതൃകമായി കിട്ടിപ്പോന്ന ഔഷധക്കൂട്ടുകൾ നിരവധി അസുഖങ്ങൾക്ക് ശമനമേകുന്നതാണ്. ആഷ്‌ലി എന്ന അദ്ദേഹത്തിന്റെയും പാർട്ണർമാരുടെയും സംയുക്ത സംരംഭം ആയുർ വേദിക് മെഡിസിൻസ്, ആയുർവേദിക് കോസ്‌മെറ്റിക് പ്രോഡക്റ്റുകൾ ഒക്കെ ഉത്പാദിപ്പിക്കുകയും വിതരണം ചെയ്യുകയും ചെയ്യുന്നുണ്ട്. അദ്ദേഹത്തിന്റെ വളരെ ഫലപ്രദമായ ഒരു സൗന്ദര്യ വർധക കൂട്ടാണ് നാച്വറൽ ഫെയ്‌സ് പാക്ക്. ഇത് ഒട്ടനവധി ആളുകളുടെ അനുഭവ സാക്ഷ്യം കൂടി ആയപ്പോൾ അതിന്റെ വിതരണ അവകാശം പൂർണ്ണമായും നമുക്ക് തന്നുകൊണ്ട് ഉണ്ടാക്കിയ കമ്പനിയാണ് വി-വൺ. ആഷ്‌ലിയും ഐഡിയൽ ഫിൻസെർവും കൂടിച്ചേർന്ന സംരംഭമാണ് ഇത്. ',
    videoId: 'eqy2MpY2soc',
    Extra: [
      {
        head: '01 / LOCKING PERIOD',
        texts:
          'ഒരു വർഷമാണ് ലോക്കിങ് പിരീഡ്, ലോക്കിങ് പിരീഡിന് മുന്നേ ഈ MXT വിൽക്കരുത്, ലോക്കിങ് പിരീഡ് ആരംഭിക്കുന്നത് ലിസ്റ്റ് ചെയ്ത ദിവസം മുതൽ ആണ് (15/02/2023)        ',
      },
      {
        head: '02 / WITHDRAWAL',
        texts: 'പിൻവലിക്കുമ്പോൾ (MXT വിൽക്കുമ്പോൾ) പ്രോസസിംഗ് ടൈം ഒരു മാസമാണ് .',
      },
      {
        head: '03 / PROFIT DIVIDING',
        texts:
          'ലാഭ വിതരണം നടക്കുന്നത് മാസത്തിൽ ഒരു തവണ എന്ന രീതിയിൽ ആയിരിക്കും. അതനുസരിച്ചായിരിക്കും ഈ MXT യുടെയും പ്രോഫിറ്റ് വിതരണം ഉണ്ടായിരിക്കുക.  ',
      },
      {
        head: '04 / DIVIDENT RADIO',
        texts:
          'കമ്പനിയിൽ നിന്ന് വരുന്ന വരുമാനത്തിന്റെ 80% ആണ് ഷെയറിനു ഉണ്ടാവുക 20% ഐഡിയൽ ഫിൻസെർവ് മാനേജ്‌മെന്റിന് ഉള്ളതാണ്.',
      },
    ],
  },
  {
    id: `U31904KL2022PTC076218`,
    name: 'KAAFTECH Innovations Pvt. Ltd.',
    img: '/assets/images/Home/miniprojetcs.png',
    imgMobile: '/assets/images/Projects/miniprojetcs.png',
    designation: 'E-LED Manufacturer',
    text: 'KAAFTECH INNOVATIONS PVT. LTD. വണ്ടൂർ അടുത്ത് വീദനശ്ശേരിയിൽ നിരവധി ENERGY EFFICIENCY കൂടിയ BLDC ഫാനുകൾ, BLDC AC കൾ, ഒപ്പം ARCHITECT ലൈറ്റുകൾ ഉൾപ്പെടെ വിവിധ പ്രൊഡക്റ്റുകൾ നിരവധി പ്രവർത്തിച്ചു. ',
    detailing:
      'ENERGY  EFFICIENCY  കൂടിയ   BLDC ഫാനുകൾ, BLDC AC കൾ,  ARCHITECT ലൈറ്റുകൾ ഉൾപ്പെടെ നിരവധി പ്രൊഡക്റ്റുകൾ ഉള്ള LUX  എഞ്ചിനീയറിംഗ് സ്ഥാപനം KAAFTECH INNOVATIONS PVT. LTD. വണ്ടൂർ അടുത്ത് വീദനശ്ശേരിയിൽ 60 സെന്റ് സ്ഥലത്തു R&D യൂണിറ്റും അസ്സംബ്ലി പോയിന്റും പണി തുടങ്ങിക്കഴിഞ്ഞു. റിസർച്ച് നടത്താൻ താൽപര്യപ്പെടുന്ന വിദ്യാർത്ഥികൾക്കും സൈന്റിസ്റ്റുകൾക്കും അതിനുള്ള സൗകര്യം ഒരുക്കിക്കൊടുക്കുകയും നല്ല കണ്ടെത്തലുകളുടെ വ്യാവസായിക അവകാശം നേടുകയും ചെയ്യുന്നതാണ്‌ കാഫ്ടെക് രീതി',
    videoId: 'eqy2MpY2soc',
    Extra: [
      {
        head: '01 / LOCKING PERIOD',
        texts: 'ഒരു വർഷം ആണ് ലോക്കിങ് പിരീഡ്, ലോക്കിങ് പിരീഡിന് മുന്നേ ഈ MXT വിൽക്കരുത്,',
      },
      {
        head: '02 / WITHDRAWAL',
        texts: 'പിൻവലിക്കുമ്പോൾ (MXT വിൽക്കുമ്പോൾ) പ്രോസസിംഗ് ടൈം 3 ആഴ്ചകൾ ആയിരിക്കും.',
      },
      {
        head: '03 / PROFIT DIVIDING',
        texts:
          'കഴിഞ്ഞ നവംബർ 2022 മുതലാണ് ഇതിന്റെ ലാഭം കൊടുത്തു തുടങ്ങിയത്. കഫ്ടെക് എന്ന കമ്പനിക്ക് ഓരോ പ്രോഡക്റ്റിലും ലഭിക്കുന്ന മാർജിൻ ആണ് ഇതിന്റെ ലാഭം. അത് ഓരോ കൺസൈമന്റിനും ലഭിക്കുന്ന മാർജിൻ ഇൻവെസ്റ്റേഴ്സിനു കൈമാറുന്ന രീതിയാണ് അവലംബിക്കുന്നത്. മാസത്തിൽ ചുരുങ്ങിയത് ഒരു കൺസൈമന്റ് എന്ന നിലയിലാണ് പ്രതീക്ഷിക്കുന്നത്.',
      },
      {
        head: '04 / DIVIDENT RADIO',
        texts:
          'കൺസൈമന്റിനും ലഭിക്കുന്ന മാർജിൻനിന്നു വരുന്ന വരുമാനത്തിന്റെ 70% ആണ് MXT ക്ക് ഉണ്ടാവുക 30% ഐഡിയൽ ഫിൻസെർവ് മാനേജ്‌മെന്റിന് ഉള്ളതാണ്.മേൽ പ്രോഡക്റ്റുകളുടെ വിൽപ്പന, LUX എഞ്ചിനീറിങ് സർവീസ് ഉൾപ്പടെ ഉള്ള സോഴ്‌സുകളിൽ നിന്ന് ഉണ്ടാകുന്നതാണ് ഈ കമ്പനിയുടെ വരുമാനം. പ്രതീക്ഷിക്കുന്ന വരുമാനം 1000 മുതൽ 3000 രൂപ വരെ പ്രതിമാസം ഒരു ലക്ഷത്തിനു എന്ന രീതിയിൽ ആണ്. ആകെ 40000 മൈക്രോ ഷെയറുകളാണ് ഉള്ളത്.',
      },
    ],
  },
];

export const eventSpecification = [
  {
    id: '1',
    eventName: 'HI Lums Dealership Distribution',
    description:
      ' Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation',
    date: 'June 22,2022',
    time: '10.00 AM',
    center: 'Inkel City,',
    place: 'RUBY LOUNGE, Third floor Daliya KPees Avenue',
    day: 32,
    hours: 19,
    minutes: 54,
    seconds: 45,
    img1: '/assets/images/event/eventimg1.jpeg',
    img2: '/assets/images/event/eventimg2.jpeg',
    img3: '/assets/images/event/eventimg3.jpeg',
    img4: '/assets/images/event/eventimg4.jpeg',
  },
  {
    id: '2',
    eventName: 'Hi Lums factory Foundation stone',
    description:
      ' Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation',
    date: 'August 22,2022',
    time: '11.00 AM',
    center: 'Inkel City,',
    place: 'MSM Auditorium, Down Hill, Malappuram',
    day: 32,
    hours: 19,
    minutes: 54,
    seconds: 45,
    img1: '/assets/images/event/event2img1.jpeg',
    img2: '/assets/images/event/event2img2.jpeg',
    img3: '/assets/images/event/event2img3.jpeg',
    img4: '/assets/images/event/event2img4.jpeg',
  },
  {
    id: '3',
    eventName: 'Ideal Eshope Launching',
    description:
      ' Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation',
    date: 'March 12,2023',
    time: '2.00 PM - 6.00 PM',
    center: 'Vyapara Bhavan',
    place: 'Shifa Convention Center, Malappuram',
    day: 32,
    hours: 19,
    minutes: 54,
    seconds: 45,
    img1: '/assets/images/event/event3img1.jpeg',
    img2: '/assets/images/event/event3img2.jpeg',
    img3: '/assets/images/event/event3img3.jpeg',
    img4: '/assets/images/event/event3img4.jpeg',
  },
  {
    id: '4',

    eventName: 'IB Club Business Conclave',
    description:
      ' Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation',
    date: 'June 22,2022',
    time: '10.00 AM',
    center: 'ADLUX Convention,',
    place: 'Centre, KOCHI',
    day: 32,
    hours: 19,
    minutes: 54,
    seconds: 45,
    img1: '/assets/images/event/events1.png',
    img2: '/assets/images/event/events.png',
    img3: '/assets/images/event/events.png',
    img4: '/assets/images/event/events1.png',
  },
];

export const aboutUsData = [
  {
    imgUrlOutLine: '/assets/images/Home/drop-image-outlne1.png',
    imgUrlWhite: '/assets/images/Home/drop-image-wt1.png',
    text: 'Exponential Growth Opportunities',
    longText: `Whether you're an investor, promoter, merchant, or buyer, IB Club offers diverse membership tiers tailored to meet your unique needs. Our dynamic structure ensures that each member finds a pathway to success.`,
  },
  {
    imgUrlOutLine: '/assets/images/Home/drop-image-outlne5.png',
    imgUrlWhite: '/assets/images/Home/drop-image-wt5.png',
    text: 'Exclusive Member Benefits',
    longText: `Whether you're an investor, promoter, merchant, or buyer, IB Club offers diverse membership tiers tailored to meet your unique needs. Our dynamic structure ensures that each member finds a pathway to success.`,
  },
  {
    imgUrlOutLine: '/assets/images/Home/drop-image-outlne2.png',
    imgUrlWhite: '/assets/images/Home/drop-image-wt2.png',
    text: 'Diverse Membership Tiers',
    longText: `Whether you're an investor, promoter, merchant, or buyer, IB Club offers diverse membership tiers tailored to meet your unique needs. Our dynamic structure ensures that each member finds a pathway to success.`,
  },
  {
    imgUrlOutLine: '/assets/images/Home/drop-image-outlne6.png',
    imgUrlWhite: '/assets/images/Home/drop-image-wt6.png',
    text: 'Local Focus, Global Impact',
    longText: `Whether you're an investor, promoter, merchant, or buyer, IB Club offers diverse membership tiers tailored to meet your unique needs. Our dynamic structure ensures that each member finds a pathway to success.`,
  },
  {
    imgUrlOutLine: '/assets/images/Home/drop-image-outlne3.png',
    imgUrlWhite: '/assets/images/Home/drop-image-wt3.png',
    text: 'Comprehensive Support Ecosystem',
    longText: `Whether you're an investor, promoter, merchant, or buyer, IB Club offers diverse membership tiers tailored to meet your unique needs. Our dynamic structure ensures that each member finds a pathway to success.`,
  },
  {
    imgUrlOutLine: '/assets/images/Home/drop-image-outlne7.png',
    imgUrlWhite: '/assets/images/Home/drop-image-wt7.png',
    text: 'Innovative Investment Solutions',
    longText: `Whether you're an investor, promoter, merchant, or buyer, IB Club offers diverse membership tiers tailored to meet your unique needs. Our dynamic structure ensures that each member finds a pathway to success.`,
  },

  {
    imgUrlOutLine: '/assets/images/Home/drop-image-outlne4.png',
    imgUrlWhite: '/assets/images/Home/drop-image-wt4.png',
    text: 'Network and Connect',
    longText: `Whether you're an investor, promoter, merchant, or buyer, IB Club offers diverse membership tiers tailored to meet your unique needs. Our dynamic structure ensures that each member finds a pathway to success.`,
  },
  {
    imgUrlOutLine: '/assets/images/Home/drop-image-outlne8.png',
    imgUrlWhite: '/assets/images/Home/drop-image-wt8.png',
    text: 'Community-Centric Values',
    longText: `Whether you're an investor, promoter, merchant, or buyer, IB Club offers diverse membership tiers tailored to meet your unique needs. Our dynamic structure ensures that each member finds a pathway to success.`,
  },
];
