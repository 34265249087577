/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import { Navigate, useRoutes } from 'react-router-dom';

// layouts
import SimpleLayout from './layouts/simple';
//
import Page404 from './pages/Page404';
import HomePage from './pages/Home-page/Home';
import AllProjects from './pages/ProjectPage';
import { ProjectSingleView } from 'pages/project-single';
import AllEvents from 'pages/Event-page';
import EventDetail from 'pages/EventSingle';
import AboutUs from 'pages/Other/About';
import TicketBooking from 'pages/EventSingle/TicketBooking';
import SubmitPage from 'pages/SubmitPage';

// ----------------------------------------------------------------------

export default function Router() {
  const routesList = [
    // public
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        {
          path: '/',
          children: [
            {
              path: 'home',
              element: <HomePage />,
            },
            {
              path: 'about',
              element: <AboutUs />,
            },
            {
              path: 'projects',
              element: <AllProjects />,
            },
            {
              path: 'events',
              element: <AllEvents />,
            },
            {
              path: 'project-detail/:id',
              element: <ProjectSingleView />,
            },
            {
              path: 'event-detail/:id',
              element: <EventDetail />,
            },
            {
              path: 'event-booking',
              element: <TicketBooking />,
            },
            {
              path: 'submit-page',
              element: <SubmitPage />,
            },
            // {
            //   path: 'terms&condition',
            //   element: <Terms />,
            // },
            // {
            //   path: 'privacy&policy',
            //   element: <Privacy />,
            // },
            // add other routes here
          ],
        },

        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },

    // handle notfound routes
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ];

  const routes = useRoutes(routesList);

  return routes;
}
