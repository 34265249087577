import { Box, Button, Grid, Link, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import styled from '@emotion/styled';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useLocation } from 'react-router-dom';

function MobileFooter() {
  const location = useLocation();

  const CustomButton = styled(Button)({
    height: 60,
    background: '#65B492',
    color: '#FFFFFF',
    borderRadius: '15px',
    fontSize: '15.084px',
    fontWeight: 'bold',
    width: '100%',
    '&:hover': {
      background: '#F8971C',
      color: '#342C2C',
    },
  });

  const [scrollValue, setScrollValue] = useState();

  React.useEffect(() => {
    const handleScroll = () => {
      const scrollValue = window.scrollY;
      setScrollValue(scrollValue);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScrollValue = () => {
    if (location?.pathname === '/event-detail') {
      return 500;
    }
    return 2000;
  };

  const footerItemsMobile = [
    {
      itemName: 'Home',
      pathName: '/home',
      activeImage: '/assets/images/common/home.png',
      inActiveImage: '/assets/images/common/home.png',
      alt: '1',
    },
    {
      itemName: 'Projects',
      pathName: '/projects',
      activeImage: '/assets/images/common/projects.png',
      inActiveImage: '/assets/images/common/projects.png',
      alt: '1',
    },
    {
      itemName: 'Events',
      pathName: '/events',
      activeImage: '/assets/images/common/events.png',
      inActiveImage: '/assets/images/common/events.png',
      alt: '1',
    },
    {
      itemName: 'About',
      pathName: '/about',
      activeImage: '/assets/images/common/about.png',
      inActiveImage: '/assets/images/common/about.png',
      alt: '1',
    },
  ];

  const footerNavigates = [
    {
      itemName: '  Login   ',
      link: 'https://mxtradings.in/',
    },
    {
      itemName: '  Register  ',
      link: 'https://mxtradings.in/investor/register/',
    },

    {
      itemName: '  Projects ',
      link: '/projects',
    },
    {
      itemName: 'Open Shares',
      link: 'https://docs.google.com/forms/d/e/1FAIpQLSd9t3Hjmabyq8c2Ael-ZboFBZN4EkLdLMiMQkLif_cM1BJnUg/viewform',
    },
  ];
  return (
    <div>
      <footer style={{ backgroundColor: '#104459', padding: '50px 0px 200px', color: '#FFF' }}>
        <Grid spacing={3} px={''} pl={5}>
          {/* Company logo */}
          <Grid display={''} justifyContent="space-around" alignItems="">
            <Grid item>
              <Box width={200}>
                <img
                  className="footerlogo"
                  src={`/assets/footer-logo.png`}
                  alt="Logo"
                  style={{ height: '60px', width: '300px', objectFit: 'contain', marginLeft: -25 }}
                />
              </Box>
            </Grid>
            <Stack sx={{ py: 2 }}>
              <Typography variant="h6" className="footer-text">
                Kepees Daliya Avenue. Up-Hill
                <br /> Malappuram, Kerala, India <br /> Email: contact@mxtideal.com <br />
                Mobile : +91 6235666620
              </Typography>
            </Stack>
            <Stack display={'flex'} flexDirection={'row'} my={0} mr={0} pr={0}>
              {footerNavigates?.map((item, key) => (
                <Typography key={key} className="footer-listes">
                  <a href={item?.link} style={{ textDecoration: 'none', color: '#fff' }}>
                    {item?.itemName} {key + 1 !== footerNavigates?.length && `|`}&nbsp;
                  </a>
                </Typography>
              ))}
            </Stack>
            <Grid item>
              <Typography
                sx={{
                  marginTop: 1,
                  color: '#FFF',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  paddingBottom: '30px',
                }}
              >
                <span className="footer-follow"> Follow Us! &nbsp;</span>
                <Box sx={{ display: 'flex', alignItems: 'center', color: '#A9A9A9' }}>
                  <InstagramIcon style={{ marginLeft: 10 }} />
                  <FacebookRoundedIcon style={{ marginLeft: 10 }} />
                  <TwitterIcon style={{ marginLeft: 10 }} />
                  <LinkedInIcon style={{ marginLeft: 10 }} />
                  <YouTubeIcon style={{ marginLeft: 10 }} />
                </Box>
              </Typography>
              <Stack mt={-3}>
                <Stack>
                  <Typography variant="" color="" fontSize={12} pt={0}>
                    Copyrights © IB Club | Powered by &nbsp;
                    <a href="https://osperb.com/" style={{ textDecoration: 'none', color: '#2B70F5', fontSize: 14 }}>
                      osperb
                    </a>
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Grid>

        <Grid px={5}>
          <Grid
            container
            spacing={1}
            className="app-bar"
            sx={{
              padding: '13px 4px 13px 4px ',
              display: { sx: 'flex', sm: 'none' },
              flexDirection: { sx: 'row' },
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: '#104459',
              borderRadius: '1px',
              justifyContent: 'space-around',
              width: 'auto',
              zIndex: 9,
            }}
          >
            {footerItemsMobile?.map((item, key) => (
              <Grid key={key}>
                <Link href={item?.pathName} style={{ textDecoration: 'none' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', md: 'column' },
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={item?.activeImage}
                      alt=""
                      width={25}
                      height={25}
                      style={{ objectFit: 'contain', marginBottom: '3px' }}
                    />

                    <Typography
                      textAlign={'center'}
                      color={'#E3E3E3'}
                      fontSize={12}
                      fontWeight={400}
                      className="mobile-link-text"
                    >
                      {item?.itemName}
                    </Typography>
                  </Box>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid
          display={'flex'}
          px={1}
          position={'fixed'}
          zIndex={1}
          width={'100%'}
          className={`floating-card ${scrollValue >= handleScrollValue() ? 'active' : ''}`}
        >
          <CustomButton type="submit" variant="contained" className="request-btn">
            {location?.pathname !== '/event-detail' ? (
              <>
                <WhatsAppIcon sx={{ fontSize: 30 }} />
                &nbsp;Enquire on WhatsApp!
              </>
            ) : (
              'BOOK YOUR TICKET'
            )}
          </CustomButton>
        </Grid>
      </footer>
    </div>
  );
}

export default MobileFooter;
