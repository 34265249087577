import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import { Button, Grid, Stack, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDevice } from '../hooks/useResponsive';

const FeedbackModal = ({ open, onClose }) => {
  const isMobile = useDevice()?.isMobile;
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: isMobile ? '100%' : '80%',
    boxShadow: 'none',
    bgcolor: 'white',
    p: isMobile ? 4 : 2,
  };

  return (
    <div className="">
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ px: 4 }}
        className="bg-image"
      >
        <Box sx={style}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            p={2}
            px={isMobile ? 5 : 2}
            sx={{ backgroundColor: '#D9F4EA' }}
          >
            <Stack className="modal-header">IB Club Business Conclave</Stack>
            <CloseIcon sx={{ color: '#34708C', cursor: 'pointer' }} onClick={onClose} />
          </Grid>
          <Stack sx={{ backgroundColor: 'white', p: 2, px: isMobile && 5 }}>
            <Stack>
              <input placeholder="Name" className="field-custom" />
              <input placeholder="Email ID" className="field-custom" />
              <input placeholder="Phone" className="field-custom" />
              <TextField
                id="outlined-multiline-static"
                label=""
                multiline
                placeholder="Address"
                rows={4}
                sx={{ my: 2, border: '', color: '#65B492', borderRadius: 0 }}
              />
            </Stack>
            <Grid pt={isMobile && 13}>
              <Button
                variant="contained"
                sx={{ width: '100%', boxShadow: 'none', backgroundColor: '#65B492' }}
                className="submit-button"
              >
                Submit
              </Button>
            </Grid>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default FeedbackModal;

FeedbackModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
