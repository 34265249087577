import React from 'react';
import { Stack } from '@mui/material';
import { useDevice } from '../../hooks/useResponsive';
import ClubActivity from '../Home-page/ClubActivity';
import { MetaHelmet } from '../../layouts/Helmet/Helmet';
import EventPage from './EventPage';

export default function AllEvents() {
  const isMobile = useDevice()?.isMobile;

  return (
    <Stack>
      <MetaHelmet title={'All Events'} />
      <Stack sx={{ backgroundColor: '#FFFFFF' }}>
        {isMobile ? (
          <>
            <EventPage />
            <ClubActivity />
            {/* <Testimonial /> */}
          </>
        ) : (
          <>
            <Stack px={17}>
              <EventPage />
              <ClubActivity />
            </Stack>
            {/* <Grid px={10}>
              <Testimonial />
            </Grid> */}
          </>
        )}
      </Stack>
    </Stack>
  );
}
