import React from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDevice } from '../../hooks/useResponsive';
import { specificationsPoints } from '../../utils/projectDetails';

function Projects() {
  const isMobile = useDevice()?.isMobile;

  return (
    <>
      {!isMobile && (
        <Typography className="subtitle-1" py={2}>
          OUR PROJECTS!
        </Typography>
      )}

      <Grid mt={isMobile && 5} mb={1.5} mx={1.5}>
        <Grid container>
          {specificationsPoints?.slice(0, 3)?.map((item, key) => (
            <Grid item md={4} lg={4} sm={12} key={key}>
              <Box className="image-box-home" mx={1} my={2}>
                <img
                  src={item?.img}
                  alt=""
                  style={{ objectFit: 'contain', height: isMobile ? 'auto' : '80%', borderRadius: 15 }}
                />
                <Stack p={2} px={3}>
                  <Typography className="mini-card-text">{item?.text}</Typography>
                  <Stack py={1}>
                    <Stack
                      className="view-project-home"
                      sx={{ textDecoration: 'none' }}
                      component={Link}
                      to={`/project-detail/${item?.id}`}
                    >
                      View Projects
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid display={'flex'} alignItems={'center'} justifyContent={'center'} p={isMobile ? 0 : 5}>
        <Button component={Link} to="/projects" variant="contained" className="view-projects-button">
          View All Projects
        </Button>
      </Grid>
    </>
  );
}

export default Projects;
