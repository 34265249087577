import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Box, Button, Grid, Link, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import FeedbackModal from '../popup';
import { useDevice } from '../../hooks/useResponsive';
import { eventSpecification } from '../../utils/projectDetails';

function EventBanner() {
  const isMobile = useDevice()?.isMobile;

  const params = useParams();
  const eventId = params?.id;

  const CustomButton = styled(Button)({
    backgroundColor: '#65B492',
    fontSize: 18,
    color: 'white',
    boxShadow: 'none',
    padding: 20,
    borderRadius: 18,
    width: isMobile ? '100%' : '',
    height: isMobile ? '100%' : '',
    '&:hover': {
      backgroundColor: '#65B492',
    },
  });

  const [popUp, setPopUp] = useState(false);

  const onClosePopUp = () => {
    setPopUp(false);
  };

  const handleFilteredData = () => {
    const newData = eventSpecification?.filter((item) => item?.id === eventId);
    return newData;
  };

  return (
    <div>
      <FeedbackModal open={popUp} onClose={onClosePopUp} />

      {handleFilteredData()?.map((item, key) => (
        <>
          <Stack key={key} height={'100%'}>
            <img
              src={item?.img1}
              alt=""
              style={{ objectFit: 'cover', objectPosition: 'top', height: '450px', width: '100%' }}
            />
          </Stack>
          <Stack sx={{ px: isMobile && 3 }}>
            <Stack sx={{ paddingTop: 3 }}>
              <Grid container>
                <Grid item md={4} lg={4} sm={12} mb={isMobile ? 3 : 0}>
                  <Typography className="event-title">{item?.eventName}</Typography>
                  <Typography className="event-description">{item?.description}</Typography>
                </Grid>
                {!isMobile ? (
                  <>
                    <Grid item md={3} lg={4} sm={12} px={isMobile ? 0 : 5}>
                      <Grid display={'flex'} alignItems={'center'} justifyContent={''} py={1}>
                        <Box
                          sx={{
                            backgroundColor: '#EDF8F4',
                            borderRadius: 2,
                            height: 50,
                            width: 50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <img
                            src="/assets/images/event/calender.png"
                            alt=""
                            width={25}
                            height={25}
                            style={{ objectFit: 'contain' }}
                          />
                        </Box>
                        <Stack mx={1}>
                          <Typography className="event-calendar-dates"> {item?.date}</Typography>
                          <Typography className="event-time">{item?.time}</Typography>
                        </Stack>
                      </Grid>

                      <Grid display={'flex'} alignItems={'center'} justifyContent={''} py={''}>
                        <Box
                          sx={{
                            backgroundColor: '#EDF8F4',
                            borderRadius: 2,
                            height: 50,
                            width: '30%',
                            maxWidth: 50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <img
                            src="/assets/images/event/location.png"
                            alt=""
                            width={25}
                            height={25}
                            style={{ objectFit: 'contain' }}
                          />
                        </Box>
                        <Stack mx={1} width={'70%'}>
                          <Typography className="event-calendar-dates">{item?.center}</Typography>
                          <Typography className="event-time-single">{item?.place}</Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid item md={5} lg={4} sm={12} sx={{ filter: 'grayscale(1)' }}>
                      <Box
                        sx={{
                          backgroundColor: '#EDF8F4',
                          borderRadius: 2,
                          padding: 2,
                        }}
                      >
                        <Grid container>
                          <Grid
                            item
                            md={3}
                            lg={3}
                            sm={3}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                          >
                            <Stack>
                              <span className="letter-events">{item?.day}</span>
                              <span className="time-string">Days</span>
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            md={3}
                            lg={3}
                            sm={3}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                          >
                            <Stack>
                              <span className="letter-events">{item?.hours}</span>
                              <span className="time-string">Hours</span>
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            md={3}
                            lg={3}
                            sm={3}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                          >
                            <Stack>
                              <span className="letter-events">{item?.minutes}</span>
                              <span className="time-string">Minutes</span>
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            md={3}
                            lg={3}
                            sm={3}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                          >
                            <Stack>
                              <span className="letter-events">{item?.seconds}</span>
                              <span className="time-string">Seconds</span>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Box>
                      <Stack sx={{ marginTop: 2 }}>
                        <CustomButton variant="contained" className="font-bold" onClick={() => setPopUp(false)}>
                          BOOK YOUR TICKET
                        </CustomButton>
                      </Stack>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid display={'flex'} alignItems={'center'} justifyContent={''} py={1} px={1}>
                      <Box
                        sx={{
                          backgroundColor: '#EDF8F4',
                          borderRadius: 2,
                          height: 50,
                          width: 50,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <img
                          src="/assets/images/event/calender.png"
                          alt=""
                          width={25}
                          height={25}
                          style={{ objectFit: 'contain' }}
                        />
                      </Box>
                      <Stack mx={1}>
                        <Typography className="event-calendar-dates">{item?.date}</Typography>
                        <Typography className="event-time">{item?.time}</Typography>
                      </Stack>
                    </Grid>

                    <Grid display={'flex'} mb={2} alignItems={'center'} justifyContent={''} py={''} px={1}>
                      <Box
                        sx={{
                          backgroundColor: '#EDF8F4',
                          borderRadius: 2,
                          height: 50,
                          width: '30%',
                          maxWidth: 50,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <img
                          src="/assets/images/event/location.png"
                          alt=""
                          width={25}
                          height={25}
                          style={{ objectFit: 'contain' }}
                        />
                      </Box>
                      <Stack mx={1} width={'70%'}>
                        <Typography className="event-calendar-dates">{item?.center}</Typography>
                        <Typography className="event-time-single">{item?.place}</Typography>
                      </Stack>
                    </Grid>

                    <Box
                      sx={{
                        backgroundColor: '#EDF8F4',
                        borderRadius: 2,
                        padding: 2,
                        width: 370,
                        filter: 'grayscale(1)',
                      }}
                    >
                      <Grid display={'flex'} alignItems={'center'} justifyContent={'space-around'}>
                        <Stack display={'flex'} alignItems={'center'}>
                          <span className="letter-events">{item?.day}</span>
                          <span className="time-string">Days</span>
                        </Stack>
                        <Stack display={'flex'} alignItems={'center'}>
                          <span className="letter-events">{item?.hours}</span>
                          <span className="time-string">Hours</span>
                        </Stack>
                        <Stack display={'flex'} alignItems={'center'}>
                          <span className="letter-events">{item?.minutes}</span>
                          <span className="time-string">Minutes</span>
                        </Stack>
                        <Stack display={'flex'} alignItems={'center'}>
                          <span className="letter-events">{item?.seconds}</span>
                          <span className="time-string">Seconds</span>
                        </Stack>
                      </Grid>
                    </Box>
                    <Stack
                      sx={{
                        marginTop: 2,
                        width: 370,
                        filter: 'grayscale(1)',
                      }}
                    >
                      <CustomButton
                        variant="contained"
                        component={Link}
                        className="font-bold"
                        // href={'/submit-page'}
                        // onClick={() => setPopUp(true)}
                      >
                        BOOK YOUR TICKET
                      </CustomButton>
                    </Stack>
                  </>
                )}
              </Grid>
            </Stack>
          </Stack>
        </>
      ))}
    </div>
  );
}

export default EventBanner;
