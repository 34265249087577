import { Box, Divider, Grid, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import Tab from '@mui/material/Tab';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import TextMaxLine from '../../components/text-max-line';
import { useDevice } from '../../hooks/useResponsive';
import { eventSpecification } from '../../utils/projectDetails';

function EventPage() {
  const isMobile = useDevice()?.isMobile;
  const [value, setValue] = React.useState('2');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div style={{ marginBottom: 5 }}>
      <Stack display={'flex'} alignItems={'center'} justifyContent={'center'} pt={isMobile ? 2 : 5}>
        <Divider sx={{ borderBottom: '1px solid rgba(101, 180, 146, 0.5)', width: '90%' }} />
      </Stack>

      <Stack display={'flex'} alignItems={'center'} justifyContent={'start'}>
        <TabContext value={value}>
          <Grid className="tab-box" sx={{ px: isMobile ? 1 : 0, mb: 3 }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example" className="tab-parent">
              <Tab label="Upcoming Events" value="1" className="tab-text" sx={{ marginX: isMobile ? 0 : 5 }}>
                Upcoming Events
              </Tab>
              <Tab label="Past Events" value="2" className="tab-text" sx={{ marginX: 5 }}>
                Past Events
              </Tab>
            </TabList>
          </Grid>
          <TabPanel value="1">
            {/* <Grid container spacing={2}>
              {eventsLists?.map((item, key) => (
                <Grid item md={6} xs={12} key={key}>
                  <Link href="/event-detail" style={{ textDecoration: 'none' }}>
                    <Stack mx={1}>
                      <img src={item?.img} alt="img" height={'100%'} width={'100%'} />
                      <Grid container spacing={1} alignItems="center">
                        <Grid item md={6} xs={6} display="flex" alignItems="center">
                          <Stack direction="row" alignItems="center" my={2}>
                            <Box
                              sx={{
                                backgroundColor: '#EDF8F4',
                                borderRadius: 2,
                                height: isMobile ? 65 : 70,
                                width: isMobile ? 65 : 70,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <img
                                src="/assets/images/event/calender.png"
                                alt=""
                                width={isMobile ? 35 : 40}
                                height={isMobile ? 35 : 40}
                                style={{ objectFit: 'contain' }}
                              />
                            </Box>
                            <Stack mx={1}>
                              <Typography className="event-calendar-dates">{item?.date}</Typography>
                              <Typography className="event-time">{item?.time}</Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item md={6} xs={6} display="flex" alignItems="center">
                          <Stack direction="row" alignItems="center">
                            <Box
                              sx={{
                                backgroundColor: '#EDF8F4',
                                borderRadius: 2,
                                height: isMobile ? 65 : 70,
                                width: isMobile ? 65 : 70,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <img
                                src="/assets/images/event/location.png"
                                alt=""
                                width={isMobile ? 35 : 40}
                                height={isMobile ? 35 : 40}
                                style={{ objectFit: 'contain' }}
                              />
                            </Box>
                            <Stack ml={1}>
                              <Typography className="event-location">{item?.venue}</Typography>
                              <Typography className="event-location">{item?.place}</Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                      </Grid>
                      <Stack display={'flex'} alignItems={'center'} justifyContent={'center'} pb={5}>
                        <Divider sx={{ borderBottom: '1px solid rgba(101, 180, 146, 0.5)', width: '100%' }} />
                      </Stack>
                    </Stack>
                  </Link>
                </Grid>
              ))}
            </Grid> */}
            {/* <img
              src="/assets/images/common/noevent.png"
              alt=""
              style={{ objectFit: 'contain', height: 500, width: '100%' }}
            /> */}

            <Box>
              <Typography
                variant="body2"
                sx={{ width: isMobile ? '85%' : '60%', margin: 'auto', textAlign: 'center', my: 10 }}
              >
                We regret to inform you that there are currently no upcoming events listed. Rest assured, we will notify
                you as soon as any new events become available.
              </Typography>
            </Box>
          </TabPanel>
          <TabPanel value="2">
            <Grid container spacing={2} className="all-event-bg">
              {eventSpecification?.slice(0, 4)?.map((item, key) => (
                <Grid item md={6} xs={12} key={key}>
                  <Link href={`/event-detail/${item?.id}`} style={{ textDecoration: 'none' }}>
                    <Stack mx={1}>
                      <img
                        src={item?.img1}
                        alt="img"
                        width={'100%'}
                        style={{ borderRadius: 15, height: isMobile ? '200px' : '300px', objectFit: 'cover' }}
                      />
                      <Grid container spacing={1} alignItems="center">
                        <Grid item md={6} xs={6} display="flex" alignItems="center">
                          <Stack direction="row" alignItems="center" my={2}>
                            <Box
                              sx={{
                                backgroundColor: '#EDF8F4',
                                borderRadius: 2,
                                height: isMobile ? 50 : 70,
                                width: isMobile ? 50 : 70,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <img
                                src="/assets/images/event/calender.png"
                                alt=""
                                width={isMobile ? 25 : 40}
                                height={isMobile ? 25 : 40}
                                style={{ objectFit: 'contain' }}
                              />
                            </Box>
                            <Stack mx={1}>
                              <Typography className="event-calendar-dates">{item?.date}</Typography>
                              <Typography className="event-time">{item?.time}</Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item md={6} xs={6} display="flex" alignItems="center">
                          <Stack direction="row" alignItems="center" width={'100%'}>
                            <Box
                              sx={{
                                backgroundColor: '#EDF8F4',
                                borderRadius: 2,
                                height: isMobile ? 50 : 70,
                                width: isMobile ? '30%' : 70,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <img
                                src="/assets/images/event/location.png"
                                alt=""
                                width={isMobile ? 20 : 40}
                                height={isMobile ? 20 : 40}
                                style={{ objectFit: 'contain' }}
                              />
                            </Box>
                            <Stack ml={1} width={'70%'}>
                              {/* <Typography className="event-location">{item?.venue}</Typography> */}
                              <TextMaxLine line={2}>
                                <Typography className="event-location">{item?.place}</Typography>
                              </TextMaxLine>
                            </Stack>
                          </Stack>
                        </Grid>
                      </Grid>
                      <Stack display={'flex'} alignItems={'center'} justifyContent={'center'} pb={isMobile ? 0 : 5}>
                        <Divider sx={{ borderBottom: '1px solid rgba(101, 180, 146, 0.5)', width: '100%' }} />
                      </Stack>
                    </Stack>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </TabPanel>
        </TabContext>
      </Stack>
    </div>
  );
}

export default EventPage;

export function bgGradient(props) {
  const direction = props?.direction || 'to bottom';
  const startColor = props?.startColor;
  const endColor = props?.endColor;
  const imgUrl = props?.imgUrl;
  const color = props?.color;

  if (imgUrl) {
    return {
      background: `linear-gradient(${direction}, ${startColor || color}, ${endColor || color}), url(${imgUrl})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    };
  }

  return {
    background: `linear-gradient(${direction}, ${startColor}, ${endColor})`,
  };
}
