import React from 'react';
// @mui
import { Stack } from '@mui/material';
import { MetaHelmet } from '../../layouts/Helmet/Helmet';
import EventCarousel from '../../segment/@common/Carousel/imageCarousel';
import AccordionPage from './Accordion';
import MANIFESTO from './mini-banner';
import Projects from './Projects';
import Banner from './Banner';
import ClubActivity from './ClubActivity';
import { useDevice } from '../../hooks/useResponsive';
import Shadow from '../../components/Shadow';

export default function HomePage() {
  const isMobile = useDevice()?.isMobile;
  return (
    <>
      <MetaHelmet title={'Home '} />
      <Stack sx={{ backgroundColor: '#FFFFFF' }}>
        <Banner />
        <EventCarousel />
        <AccordionPage />
        <MANIFESTO />
        <Stack px={isMobile ? 0 : 10} sx={{ position: 'relative' }}>
          <Shadow
            type="left"
            style={{
              width: '200px',
              top: '-14rem',
            }}
          />
          <Projects />
          <ClubActivity />
          {/* <Testimonial /> */}
        </Stack>
      </Stack>
    </>
  );
}
