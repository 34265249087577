import React from 'react';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import { useDevice } from '../../../hooks/useResponsive';

function Policy() {
  const isMobile = useDevice()?.isMobile;
  return (
    <div>
      <Stack mx={isMobile ? 2 : ''} mt={5}>
        <Grid mb={2}>
          <Typography className="policy-title">IDEAL BUSINESS POLICY</Typography>
        </Grid>
        <Grid container>
          <Grid item md={6} lg={6} sm={12} px={isMobile ? 0 : 2}>
            <Stack py={2}>
              <Typography className="policy-sub-heading">VERY LOW RISK</Typography>
              <Stack display={''} alignItems={''} justifyContent={''}>
                <Divider
                  sx={{ borderBottom: '2px solid rgba(101, 180, 146, 0.5)', width: isMobile ? '100%' : '60%' }}
                />
              </Stack>
              <Stack className="description-text-normal" py={1}>
                ഫിക്സഡ് ഡെപ്പോസിറ്റ്, മണി മാർക്കറ്റ് ഇൻവെസ്റ്റ്മെന്റ് തുടങ്ങിയ ബിസിനസുകളാണ് ഈ കാറ്റഗറിയിൽ പെടുന്നത്.
                ഇത്തരം ബിസിനസുകൾ ഐഡിയൽ ഫിൻസർവ് നേരിട്ട് നടത്തുകയോ ഏറ്റെടുത്ത് നടത്തുകയോ ചെയ്യുന്നില്ല.
              </Stack>
            </Stack>
            <Stack py={1}>
              <Typography className="policy-sub-heading">HIGH RISK</Typography>
              <Stack display={''} alignItems={''} justifyContent={''}>
                <Divider
                  sx={{ borderBottom: '2px solid rgba(101, 180, 146, 0.5)', width: isMobile ? '100%' : '60%' }}
                />
              </Stack>
              <Stack>
                <Typography className="description-text-normal">
                  ലീവറേജ് കമ്പനികൾ, ക്രിപ്റ്റോ ട്രേഡിംഗ് തുടങ്ങിയ ബിസിനസുകൾ. ഇത്തരം ബിസിനസുകളിൽ നിന്നും ഐഡിയൽ ഫിൻസർവ്
                  വിട്ടു നിൽക്കുന്നു.
                </Typography>
              </Stack>
            </Stack>
            <Stack py={1}>
              <Typography className="policy-sub-heading">MEDIUM RISK</Typography>
              <Stack display={''} alignItems={''} justifyContent={''}>
                <Divider
                  sx={{ borderBottom: '2px solid rgba(101, 180, 146, 0.5)', width: isMobile ? '100%' : '60%' }}
                />
              </Stack>
              <Stack>
                <Typography className="description-text-normal">
                  ഇക്വിറ്റി നിക്ഷേപങ്ങൾ, കമ്പനി പ്രൊജക്ടുകളിലെ നിക്ഷേപങ്ങൾ തുടങ്ങിയവ.
                </Typography>
              </Stack>
            </Stack>
            <Stack py={2}>
              <Typography className="policy-sub-heading">LOW RISK</Typography>
              <Stack display={''} alignItems={''} justifyContent={''}>
                <Divider sx={{ borderBottom: '2px solid rgba(101, 180, 146, 0.5)', width: '60%' }} />
              </Stack>
              <Stack>
                <Typography className="description-text-normal">
                  റിയൽ എസ്റ്റേറ്റ്, ഗോൾഡ് പോലുള്ള ബിസിനസുകളാണിവ.
                </Typography>
              </Stack>
            </Stack>
            <Stack py={1}>
              <Typography className="description-text-bold">
                ഇതിൽ മീഡിയം, ലോ റിസ്കിൽ റൺ ചെയ്യാവുന്ന ബിസിനസുകളെയാണ് IB Club നേരിട്ട് നടത്തുകയോ ഫണ്ട് ചെയ്യുകയോ ഉള്ളൂ.
                മികച്ച മാർക്കറ്റ് വിദഗ്ധരെ ഉപയോഗിച്ച് ബിസിനസിന്റെ വളർച്ചാസാധ്യത പഠന വിധേയമാക്കിയ ശേഷം മാത്രമേ IB Club
                ഒരു ബിസിനസ് പ്രൊജക്ട് ഏറ്റെടുക്കുയോ ഫണ്ടിംഗ് ചെയ്യുകയോ ഉള്ളൂ. ബിസിനസിന്റെ ധാർമിക-നിയമ വശങ്ങളും
                കൃത്യമാണെന്ന് ഉറപ്പ് വരുത്താൻ IB Club ബദ്ധശ്രദ്ധരാണ്.
              </Typography>
            </Stack>
          </Grid>
          <Grid md={6} lg={6} sm={12} px={0}>
            <Stack>
              <Typography className="description-text-normal" py={2}>
                IB Club നേരിട്ട് നടത്തുന്നതല്ലാത്ത ബിസിനസുകളെ നിരന്തരം മോണിറ്ററിങ് നടത്തി രാജ്യ നിയമങ്ങൾക്കോ
                ധാർമികതയ്ക്കോ നിരക്കാത്ത വ്യവഹാരങ്ങളിൽ ഏർപ്പെടുന്നതായി കണ്ടാൽ അത്തരം ബിസിനസുകളിൽ നിന്ന് നിക്ഷേപങ്ങൾക്ക്
                കോട്ടം തട്ടാത്ത രീതിയിൽ IB Club തങ്ങളുടെ നിക്ഷേപം നിയമപരമായി തന്നെ പിൻവലിക്കുന്നതാണ്.
              </Typography>
              <Typography className="description-text-bold" py={2}>
                IB Clubൽ 3000 രൂപ രജിസ്ട്രേഷൻ ഫീ ഉൾപ്പെടെ ചുരുങ്ങിയത് 5000 രൂപ മുതൽ നിക്ഷേപങ്ങൾ ആരംഭിക്കുന്നു.
                ഉപയോക്താവിന് എളുപ്പത്തിൽ ഉപയോഗിക്കാവുന്ന ഒരു വെബ്സൈറ്റ് വഴി IB ക്ലബ്ന് കീഴിലെ മുഴുവൻ ബിസിനസുകളെയും
                നിക്ഷേപം നടത്തുന്ന ഓരോ പാർട്ണർക്കും കൃത്യമായി മോണിറ്ററിങ് നടത്താനുള്ള അവസരമുണ്ട്. സ്ഥാപനത്തിന്റെ
                വളർച്ചയെ പാർട്ണർമാർക്ക് എളുപ്പത്തിൽ മനസിലാക്കാവുന്ന വിധത്തിൽ വളരെ സുതാര്യമാണ് IB Club ഇൻവെസ്റ്റ്
                മാനേജ്മെന്റ് വെബ്സൈറ്റ്.
              </Typography>
              <Typography className="description-text-normal" py={2}>
                നിങ്ങളുടെ സ്ഥിര വരുമാനത്തിനൊപ്പം ഒരു അധിക വരുമാനം നിങ്ങളുടെ സാമ്പത്തിക ഭദ്രതയ്ക്ക് സഹായകമാകും. വളരുന്ന
                ലോകത്തെ മാറി വരുന്ന സാഹചര്യങ്ങളിൽ തൊഴിൽ വരുമാനത്തിനപ്പുറം ഒരു നിക്ഷേപം നൽകുന്ന സുരക്ഷിതത്വം ആണ്
                നിങ്ങളാഗ്രഹിയ്ക്കുന്നതെങ്കിൽ അതിനവസരമൊരുക്കാൻ IB Club നിങ്ങളെ സഹായിക്കാം.
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </div>
  );
}

export default Policy;
