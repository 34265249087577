import React from 'react';
import Container from '@mui/material/Container';
import { Grid, Box, Typography, Stack } from '@mui/material';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useDevice } from '../../hooks/useResponsive';
import MobileFooter from './MobileFooter';

const Footer = () => {
  // const location = useLocation();
  const isMobiles = useDevice();
  const isMobile = isMobiles?.isMobile;

  console.log(isMobiles);

  // console.log('data :', scrollValue);

  const footerCompanyItems = [
    {
      itemName: 'Course',
      link: '/course',
    },
    {
      itemName: 'STEM Aptitude Test',
      link: '/',
    },

    {
      itemName: 'Stem & Tinkering LAB',
      link: '/steam&Tinkering-lab',
    },
    {
      itemName: 'STEM Facilitator',
      link: '/stem-facilitator',
    },
  ];

  const footerQuickItems = [
    {
      itemName: 'About Us',
      link: '/',
    },
    {
      itemName: 'Contact Us',
      link: '/',
    },

    {
      itemName: 'Terms&Condition',
      link: '/terms&condition',
    },
    {
      itemName: 'Privacy&Policy',
      link: '/privacy&policy',
    },
  ];

  const footerNavigates = [
    {
      itemName: 'Login',
      link: 'https://mxtradings.in/',
    },
    {
      itemName: 'Register',
      link: 'https://mxtradings.in/investor/register/',
    },
    {
      itemName: 'Projects',
      link: '/projects',
    },
    {
      itemName: 'Open Shares',
      link: 'https://docs.google.com/forms/d/e/1FAIpQLSd9t3Hjmabyq8c2Ael-ZboFBZN4EkLdLMiMQkLif_cM1BJnUg/viewform',
    },
  ];

  return (
    <>
      {isMobile ? (
        <>
          <MobileFooter company={footerCompanyItems} quick={footerQuickItems} navigates={footerNavigates} />
        </>
      ) : (
        <>
          <footer style={{ backgroundColor: '#104459', padding: '110px', color: '#FFF' }}>
            <Container>
              <Grid container spacing={3}>
                {/* Company logo */}
                <Grid container justifyContent="space-around" alignItems="center">
                  <Grid pb={4}>
                    <Box width={200}>
                      <img
                        className="footerlogo"
                        src={`/assets/footer-logo.png`}
                        alt="Logo"
                        style={{ height: '60px', width: '300px', objectFit: 'contain', marginLeft: -13 }}
                      />
                    </Box>
                  </Grid>
                  <Stack sx={{ py: 2 }}>
                    <Typography variant="h6" className="footer-address">
                      Kepees Daliya Avenue. Up-Hill
                      <br /> Malappuram, Kerala, India <br /> Email: contact@mxtideal.com <br />
                      Mobile : +91 6235666620
                    </Typography>
                  </Stack>
                  <Stack>
                    {footerNavigates?.map((item, key) => (
                      <a href={item?.link} key={key} style={{ textDecoration: 'none', color: '#fff' }}>
                        <Typography className="footer-listes">{item?.itemName}</Typography>
                      </a>
                    ))}
                  </Stack>
                  <Grid item>
                    <Typography
                      className=""
                      sx={{
                        marginTop: 1,
                        color: '#FFF',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        paddingBottom: '30px',
                      }}
                    >
                      <span className="follow-us" style={{ color: '#FFFFFF' }}>
                        Follow Us! &nbsp;
                      </span>
                      <Box sx={{ display: 'flex', alignItems: 'center', color: '#A9A9A9' }}>
                        <InstagramIcon sx={{ mx: 2, fontSize: 20 }} />
                        <FacebookRoundedIcon sx={{ mx: 2, fontSize: 20 }} />
                        <TwitterIcon sx={{ mx: 2, fontSize: 20 }} />
                        <LinkedInIcon sx={{ mx: 2, fontSize: 20 }} />
                        <YouTubeIcon sx={{ mx: 2, fontSize: 20 }} />
                      </Box>
                    </Typography>
                    <Stack direction="row" sx={{ my: -1 }}>
                      <Typography variant="" color="" className="footer-copyRight">
                        Copyrights © IB CLub &nbsp; &nbsp;| &nbsp; Design & Developed by &nbsp;
                      </Typography>
                      <a
                        href="https://osperb.com/"
                        className="osperb-font"
                        style={{ textDecoration: 'none', color: '#2B70F5' }}
                      >
                        osperb
                      </a>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </footer>
          {/* <Stack display={'flex'} alignItems={'center'} justifyContent={'center'} padding={2}>
            <Typography className="footer-copyrights">
              <span style={{ color: '#767676' }}>© 2024</span>
              <span style={{ color: '#A9A9A9' }}> www.STEMxpert.com</span>{' '}
              <span style={{ color: '#767676' }}>All rights reserved. Powered by</span>{' '}
              <a href="https://osperb.com/" style={{ textDecoration: 'none', color: '#2B70F5' }}>
                {' '}
                osperb
              </a>
            </Typography>
          </Stack> */}
        </>
      )}
    </>
  );
};

export default Footer;
