import { Stack } from '@mui/material';
import React from 'react';
import LazyLoad from 'react-lazyload';

function Loading() {
  return (
    <Stack style={{ backgroundColor: '#FFFFFF', height: '100vh' }}>
      <Stack style={{ display: 'block', margin: 'auto' }}>
        <LazyLoad height={50}>
          <img
            src={'/assets/images/loading.gif'}
            alt="fzone-loading"
            height={'40px'}
            className="p-1 tada-animation"
            width={40}
            title="Loading-babbyday"
            loading="eager"
          />
        </LazyLoad>
      </Stack>
    </Stack>
  );
}

export default Loading;
