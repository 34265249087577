import React from 'react';
import { Button, Stack } from '@mui/material';
import styled from '@emotion/styled';
import { useDevice } from '../../hooks/useResponsive';
import MobileBanner from './MobileBanner';
import Shadow from '../../components/Shadow';

function Banner() {
  const isMobile = useDevice()?.isMobile;

  const CustomButton = styled(Button)({
    // height: 60,
    background: '#65B492',
    color: '#FFFFFF',
    borderRadius: '0px',
    fontSize: '15.084px',
    fontWeight: 'bold',
    width: '250px',
    boxShadow: 'none',
    '&:hover': {
      background: '#65B492',
      color: '#FFFFFF',
    },
  });

  const CustomButton2 = styled(Button)({
    // height: 60,
    background: '#34708C',
    color: '#FFFFFF',
    borderRadius: '0px',
    fontSize: '15.084px',
    fontWeight: 'bold',
    width: '150px',
    boxShadow: 'none',
    '&:hover': {
      background: '#34708C',
      color: '#FFFFFF',
    },
  });

  return (
    <div>
      <Stack position={'relative'}>
        {!isMobile && (
          <Shadow
            type="right"
            style={{
              top: '15rem',
              width: 500,
            }}
          />
        )}
        {isMobile ? (
          <MobileBanner />
        ) : (
          <Stack zIndex={2} className="main-banner" alignItems={'end'} textAlign={'start'} justifyContent={'center'}>
            <Stack flexDirection={''} px={40}>
              <span className="home-page-main-text1">Exponential</span>
              <span className="home-page-main-text2">
                Business <span className="home-page-main-text1">Dreams,</span>
              </span>

              <span className="home-page-main-text2">
                Endless <span className="home-page-main-text2">Mutual</span>
              </span>
              <span className="home-page-main-text1">Possibilities!</span>
              <Stack sx={{ flexGrow: 0 }}>
                <a href=" https://mxtradings.in/investor/register/" target="_blank" rel="noreferrer">
                  <CustomButton type="submit" variant="contained" className="join-ib-button">
                    Join IB Club Today!
                  </CustomButton>
                </a>
              </Stack>
              <Stack sx={{ flexGrow: 0, pt: 1 }}>
                <a href="https://mxtradings.in/" target="_blank" rel="noreferrer">
                  <CustomButton2 type="submit" variant="contained" className="join-ib-button">
                    Login
                  </CustomButton2>
                </a>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
    </div>
  );
}
export default Banner;
