import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useDevice } from '../../hooks/useResponsive';
import Shadow from '../../components/Shadow';

function MANIFESTO() {
  const isMobile = useDevice()?.isMobile;
  return (
    <Stack className="bg-image-2" px={isMobile ? 0 : 10} sx={{ zIndex: 1 }}>
      <Grid
        style={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          position: 'relative',
        }}
      >
        {isMobile && (
          <Shadow
            type="right"
            style={{
              top: '4rem',
              zIndex: 1,
              width: '150px',
            }}
          />
        )}
        <Stack style={{ flex: isMobile ? 'none' : '1', padding: 0 }}>
          <img
            src={`/assets/images/Home/mini-banner.png`}
            alt=""
            height={isMobile ? '100%' : '85%'}
            width={'100%'}
            style={{
              objectFit: 'cover',
              objectPosition: 'top',
            }}
          />
        </Stack>
        <Stack className={isMobile ? `poligon-mob` : 'poligon'} style={{ flex: '1', marginLeft: isMobile ? '' : -70 }}>
          <Grid
            style={{
              backgroundColor: '#EDF8F4',
              padding: 16,
              paddingTop: 40,
              paddingBottom: 40,
              textAlign: 'center',
              height: isMobile ? 'auto' : '85%',
            }}
          >
            <Box sx={{ width: '80%', margin: 'auto' }}>
              <Typography className="our-manifesto-text">OUR MANIFESTO</Typography>
              <span className="sub-title-manifesto-regular">IB Club is committed to {isMobile ? <br /> : ''} </span>
              &nbsp;
              <span className="sub-title-manifesto-bold">
                fostering a thriving
                {isMobile ? '' : <br />}business community
              </span>{' '}
              &nbsp;
              <span className="sub-title-manifesto-regular">
                through our
                {isMobile ? '' : <br />}membership and comprehensive support,
                <br />
              </span>
              &nbsp;
              <span className="sub-title-manifesto-bold">
                enabling them to invest, promote, and
                {isMobile ? '' : <br />}connect
              </span>{' '}
              &nbsp;
              <span className="sub-title-manifesto-regular">with each other for</span> &nbsp;
              <span className="sub-title-manifesto-bold">
                mutual growth
                {isMobile ? '' : <br />}and exponential success.
              </span>
              &nbsp;
            </Box>
          </Grid>
        </Stack>
      </Grid>
    </Stack>
  );
}

export default MANIFESTO;
