import { Button, Grid, Stack, TextField } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { useDevice } from '../hooks/useResponsive';

function SubmitPage() {
  const isMobile = useDevice()?.isMobile;

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  return (
    <div>
      <Grid
        className="img-bg"
        sx={{
          backgroundImage: `url('https://example.com/assets/images/Home/grad.png')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'none',
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          p={4}
          px={isMobile ? 2 : 2}
          sx={{ backgroundColor: '#D9F4EA' }}
        >
          <Stack className="modal-header">IB Club Business Conclave</Stack>
          <CloseIcon sx={{ color: '#34708C', cursor: 'pointer' }} onClick={goBack} />
        </Grid>
        <Stack sx={{ backgroundColor: 'white', p: 2, px: isMobile && 5 }}>
          <Stack>
            <input placeholder="Name" className="field-custom" style={{ '::placeholder': { color: '#65B492' } }} />
            <input placeholder="Email ID" className="field-custom" style={{ '::placeholder': { color: '#65B492' } }} />
            <input
              placeholder="Phone Number"
              className="field-custom"
              style={{ '::placeholder': { color: '#65B492' } }}
            />
            <TextField
              id="outlined-multiline-static"
              label=""
              multiline
              placeholder="Address"
              rows={4}
              sx={{
                my: 2,
                borderRadius: 0,
                '& input::placeholder': {
                  color: '#65B492',
                },
              }}
            />
          </Stack>
          <Grid display={'flex'} alignItems={'end'} justifyContent={'end'} pt={15}>
            <Button
              variant="contained"
              sx={{ width: '100%', GridShadow: 'none', backgroundColor: '#65B492' }}
              className="submit-button"
            >
              Submit
            </Button>
          </Grid>
        </Stack>
      </Grid>
    </div>
  );
}

export default SubmitPage;
