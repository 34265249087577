import React from 'react';
import PropTypes from 'prop-types';

function Shadow({ type, style, isBottom }) {
  return (
    <>
      {isBottom ? (
        <>
          {type === 'left' ? (
            <img
              style={{
                position: 'absolute',
                bottom: '0',
                left: 0,
                width: 300,
                zIndex: 0,
                ...style,
              }}
              src="/assets/images/Home/shadows/shadow-1.png"
              alt="shadow"
            />
          ) : (
            <img
              style={{
                position: 'absolute',
                bottom: '0',
                right: 0,
                width: 300,
                zIndex: 0,
                ...style,
              }}
              src="/assets/images/Home/shadows/shadow-2.png"
              alt="shadow"
            />
          )}
        </>
      ) : (
        <>
          {type === 'left' ? (
            <img
              style={{
                position: 'absolute',
                top: '0',
                left: 0,
                width: 300,
                zIndex: 0,
                ...style,
              }}
              src="/assets/images/Home/shadows/shadow-1.png"
              alt="shadow"
            />
          ) : (
            <img
              style={{
                position: 'absolute',
                top: '0',
                right: 0,
                width: 300,
                zIndex: 0,
                ...style,
              }}
              src="/assets/images/Home/shadows/shadow-2.png"
              alt="shadow"
            />
          )}
        </>
      )}
    </>
  );
}

export default Shadow;

Shadow.propTypes = {
  type: PropTypes.string,
  style: PropTypes.object,
  isBottom: PropTypes.bool,
};
