import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useDevice } from '../../hooks/useResponsive';
import VideoPlayer from '../../segment/@common/Video/VideoPlayer';
import { specificationsPoints } from '../../utils/projectDetails';
import Shadow from '../../components/Shadow';

function ProjectSingleDetail() {
  const isMobile = useDevice()?.isMobile;
  const params = useParams();

  const productId = params?.id;

  const handleFilteredData = () => {
    const newData = specificationsPoints?.filter((item) => item?.id === productId);
    return newData;
  };

  return (
    <Stack sx={{ mx: isMobile ? 0 : 10, mb: 10, px: 1 }}>
      {isMobile && (
        <Grid container my={3} mb={2}>
          <Divider sx={{ backgroundColor: '#65B492', margin: 'auto', width: '95%' }} />
        </Grid>
      )}

      {handleFilteredData()?.map((item, key) => (
        <React.Fragment key={key}>
          <Grid container mt={isMobile ? 0 : 6}>
            <Grid item md={6} lg={6} sm={12}>
              {!isMobile && <Divider sx={{ backgroundColor: '#65B492', margin: 'auto', width: '100%' }} />}
              <Stack padding={isMobile ? 1 : 3} mx={isMobile ? 0 : 2}>
                <Typography className="project-single-name">{item?.name}</Typography>
                <Typography className="project-single-designation" mb={2}>
                  {item?.designation}
                </Typography>
              </Stack>
              <Stack>
                <Grid container>
                  <Grid className="image-box-project" mx={1}>
                    <Stack>
                      <img
                        src={item?.img}
                        alt=""
                        style={{
                          // objectFit: 'cover',
                          objectPosition: '',
                          width: '100%',
                          height: 250,
                          borderRadius: 5,
                        }}
                      />
                    </Stack>
                    <Stack py={5} px={0}>
                      <Typography className="mini-card-text-project-single">{item?.detailing}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
              <Stack padding={2} position={'relative'}>
                {isMobile && (
                  <Shadow
                    type="right"
                    style={{
                      top: '-5rem',
                      right: '-10px',
                      width: '200px',
                    }}
                  />
                )}
                <Button style={{ zIndex: 2 }} variant="contained" className="project-button1">
                  Download Profile
                </Button>
                <Button style={{ zIndex: 2 }} variant="outlined" className="project-button2">
                  Watch Video Explanation
                </Button>
              </Stack>
            </Grid>

            <Grid item md={6} lg={6} sm={12} mt={isMobile && 2} zIndex={2}>
              <VideoPlayer videoId={item?.videoId} height={isMobile ? '' : 220} width={isMobile ? 250 : 560} />
              <Stack padding={2} px={isMobile ? 1.5 : 3.5}>
                {item?.Extra?.map((extraItem, index) => (
                  <Stack key={index} mx={isMobile ? 0 : 2}>
                    <Grid py={isMobile ? 1 : 2}>
                      <Typography className="specification-list-heading" mb={1.5}>
                        {extraItem?.head}
                      </Typography>
                      <Stack>
                        <Typography variant="body2" className="project-single-page-points">
                          {extraItem?.texts}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Stack>
                ))}

                <Box mt={6} position={'relative'}>
                  {isMobile && (
                    <Shadow
                      type="left"
                      style={{
                        top: '-20rem',
                        left: '-20px',
                      }}
                    />
                  )}
                  <Typography className="project-single-name">Returns of Investment</Typography>
                  <Typography className="project-single-page-points" py={2}>
                    നിലവിൽ 3 മാസം കൂടുമ്പോഴാണ് ഈ കമ്പനിയുടെ ലാഭ വിതരണം നടക്കുന്നത്. അതനുസരിച്ചായിരിക്കും ഈ MXT യുടെയും
                    പ്രോഫിറ്റ് വിതരണം ഉണ്ടായിരിക്കുക.
                  </Typography>
                </Box>
                <Divider />
              </Stack>
            </Grid>
          </Grid>
        </React.Fragment>
      ))}
    </Stack>
  );
}

export default ProjectSingleDetail;
