import React from 'react';
import { Stack } from '@mui/material';
import { MetaHelmet } from '../../layouts/Helmet/Helmet';
import ProjectSingleDetail from './singleDetail';

export function ProjectSingleView() {
  return (
    <>
      <MetaHelmet title={'Project Detail'} />
      <Stack sx={{ backgroundColor: '#FFFFFF' }}>
        <ProjectSingleDetail />
      </Stack>
    </>
  );
}
