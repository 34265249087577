import { Outlet, useLocation } from 'react-router-dom';

import { Container, Stack } from '@mui/material';
import Footer from './Footer';
import Header from './Header';

export default function SimpleLayout() {
  const location = useLocation();
  return (
    <>
      <Container maxWidth="xl" style={{ backgroundColor: '#FFFFF', paddingRight: 0, paddingLeft: 0 }}>
        {location?.pathname === '/submit-page' ? (
          <>
            <Outlet />
          </>
        ) : (
          <>
            <Stack direction={'column'} className="header-links-main">
              <Header />
            </Stack>
            <Outlet />

            <Footer />
          </>
        )}
      </Container>
    </>
  );
}
