import { Accordion, AccordionDetails, AccordionSummary, Container, Stack, Typography } from '@mui/material';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import React, { useState } from 'react';
import Masonry from 'react-masonry-css';
import PropTypes from 'prop-types';
import Shadow from '../../components/Shadow';
import { useDevice } from '../../hooks/useResponsive';
import { aboutUsData } from '../../utils/projectDetails';

function AccordionPage({ type }) {
  const [toggleBrand, setToggleBrand] = React.useState('');
  const isMobile = useDevice()?.isMobile;

  const chunkSize = 4; // Number of items per column

  const handleAccordionClick = (key) => {
    setToggleBrand(key);
  };

  const chunkArray = (arr, size) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (_, index) => arr.slice(index * size, index * size + size));

  const dropImageChunks = chunkArray(aboutUsData, chunkSize);
  const [openAccordion, setOpenAccordion] = useState(null);
  const handleClose = (key) => {
    setOpenAccordion(openAccordion === key ? null : key);
  };

  // Function to split array into chunks

  // Split dropImage array into chunks

  return (
    <>
      <Stack px={type === 'about' ? 0 : isMobile ? 2 : 12} py={isMobile ? 3 : 5} className="bg-image">
        <Typography className="subtitle-1">WHY IB CLUB ?</Typography>
        <Typography className="subtitle-2" sx={{ textTransform: 'capitalize' }}>
          a journey of growth, connection, and prosperity with IB Club. Your success story begins here.
        </Typography>
      </Stack>

      <Container
        style={{
          position: 'relative',
          paddingBottom: 50,
          paddingTop: isMobile ? 10 : 50,
        }}
      >
        {type !== 'noShadow' && isMobile && <Shadow style={{ width: 400 }} type="left" />}

        <Masonry
          breakpointCols={{ default: 2, 768: 1, 1024: 1 }}
          className="my-masonry-grid "
          columnClassName="my-masonry-grid_column"
          style={{ width: '100%', display: 'flex' }}
        >
          {dropImageChunks?.map((column, columnIndex) => (
            <React.Fragment key={columnIndex}>
              {column.map((item, index) => {
                const key = index + columnIndex * chunkSize;
                return (
                  <Accordion
                    key={key}
                    expanded={openAccordion === key}
                    onChange={() => handleClose(key)}
                    style={{ marginLeft: isMobile ? 0 : 15, marginBottom: 15 }}
                    className={key !== 0 && key !== 7 && 'accordion-shadow'}
                  >
                    <AccordionSummary
                      onClick={() => handleAccordionClick(key)}
                      className="dropdown-section "
                      sx={{ backgroundColor: toggleBrand === key ? '#65B492' : '#EDF8F4', padding: isMobile ? 1 : 2 }}
                      expandIcon={
                        <ArrowDropDownRoundedIcon
                          sx={{ color: toggleBrand === key ? 'white' : '#65B492', fontSize: 30 }}
                        />
                      }
                      aria-controls={`panel${key}-content`}
                      id={`panel${key}-header`}
                    >
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          src={toggleBrand === key ? item?.imgUrlWhite : item?.imgUrlOutLine}
                          height={25}
                          width={25}
                          alt=""
                          style={{ objectFit: 'contain' }}
                        />
                        <span
                          className="drop-mini-text"
                          style={{ color: toggleBrand === key ? '#FFFFFF' : '#34708C', marginLeft: 10 }}
                        >
                          {item?.text}
                        </span>
                      </span>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 2 }} className="drop-long-text" id={`panel${key}-content`}>
                      {item?.longText}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </React.Fragment>
          ))}
        </Masonry>
      </Container>
    </>
  );
}

export default AccordionPage;
AccordionPage.propTypes = {
  type: PropTypes.any,
};
