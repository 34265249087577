import { Stack } from '@mui/material';
import React from 'react';
import Form from './Form';

function TicketBooking() {
  return (
    <Stack sx={{ backgroundColor: '#FFFFFF' }}>
      <Form />
    </Stack>
  );
}

export default TicketBooking;
