import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Link, useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Drawer, Grid, List, Stack } from '@mui/material';
// eslint-disable-next-line import/order, import/no-unresolved
import { useDevice } from 'hooks/useResponsive';
import styled from '@emotion/styled';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import MilitaryTechRoundedIcon from '@mui/icons-material/MilitaryTechRounded';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import Logo from '../../components/logo/Logo';

function Header() {
  const { isMobile } = useDevice();
  const location = useLocation();

  console.log(location);

  // const [setAnchorElNav] = React.useState(null);

  // const handleCloseNavMenu = () => {
  //   setAnchorElNav(null);
  // };

  const CustomButton = styled(Button)({
    // height: 60,
    background: '#65B492',
    color: '#FFFFFF',
    borderRadius: '0px',
    fontSize: '15.084px',
    fontWeight: 'bold',
    width: '100%',
    boxShadow: 'none',
    '&:hover': {
      background: '#65B492',
      color: '#FFFFFF',
    },
  });

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const pages = [
    { item: 'Home', link: '/home', icon: <HomeRoundedIcon sx={{ color: '#65b492' }} /> },
    { item: 'About Us', link: '/about', icon: <ErrorOutlineRoundedIcon sx={{ color: '#65b492' }} /> },
    { item: 'Projects', link: '/projects', icon: <FileCopyRoundedIcon sx={{ color: '#65b492' }} /> },
    { item: 'Events', link: '/events', icon: <MilitaryTechRoundedIcon sx={{ color: '#65b492' }} /> },
  ];

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250,
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Stack container justifyContent={'space-between'} padding={1}>
        <CloseIcon color="" sx={{ color: '#65B492' }} onClose={() => toggleDrawer(anchor, false)} />
      </Stack>

      <Divider />
      <List>
        {pages?.map((page, key) => (
          <>
            <Link key={key} to={page?.link} style={{ textDecoration: 'none' }}>
              <Typography
                className="font-drawer-link"
                sx={{
                  color: 'black',
                  py: 1.5,
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: 2,
                }}
              >
                {page?.icon}&nbsp;
                {page?.item}
              </Typography>
            </Link>
          </>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: '#FFFFFF',
        boxShadow: 'none',
        p: { xs: 0, sm: 0 },
        px: { xs: 0, sm: 10 },
        pb: { xs: 1 },
        pt: { xs: 1 },
      }}
    >
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          {/* mobile view */}
          {/* <Grid className="header-links">
          
          </Grid> */}
          <Grid container justifyContent="space-between" alignItems={'center'}>
            <Stack>
              <Logo />
            </Stack>
            <Stack>
              {isMobile === true ? (
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                  <Stack>
                    {['right'].map((anchor) => (
                      <React.Fragment key={anchor}>
                        <Stack onClick={toggleDrawer(anchor, true)}>
                          <Stack>
                            <img
                              src={'/assets/images/common/list.png'}
                              style={{ height: 30, width: 25, objectFit: 'contain' }}
                              alt=""
                            />
                          </Stack>
                        </Stack>
                        <Drawer anchor={anchor} open={state[anchor]}>
                          {list(anchor)}
                        </Drawer>
                      </React.Fragment>
                    ))}
                  </Stack>
                </Box>
              ) : (
                <Stack className="header-links" display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                  {pages?.map((page, key) => (
                    <>
                      <Button
                        className="font-regular"
                        key={key}
                        component={Link}
                        to={page?.link}
                        sx={{
                          my: 2,
                          color: '#34708C',
                          mx: 5,
                          fontFamily: '',
                          borderBottom: location?.pathname === page?.link && '2px solid #65B492',
                          borderRadius: 0,
                          padding: 0,
                          width: 'fit-content',
                        }}
                      >
                        {page?.item}
                      </Button>
                    </>
                  ))}
                </Stack>
              )}
            </Stack>
            {!isMobile && (
              <Stack sx={{ flexGrow: 0 }}>
                <a href=" https://mxtradings.in/investor/register/" target="_blank">
                  <CustomButton type="submit" variant="contained" className="join-ib-button">
                    Join IB Club Today!
                  </CustomButton>
                </a>
              </Stack>
            )}
          </Grid>

          {/* web view */}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
