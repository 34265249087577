import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { useEffect, useState } from 'react';
import { SnackbarAlert } from './components/snack/snackAlert';

// routes
import Router from './routes';

// theme
import ThemeProvider from './theme';

// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';

// store
import store from './server/store/store';

// style
import './style/custom.scss';
import Loading from './segment/Loading/loading';

// ----------------------------------------------------------------------

export default function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          {loading ? (
            <Loading />
          ) : (
            <>
              <ScrollToTop />
              <StyledChart />
              <Provider store={store}>
                <SnackbarAlert />
                <Router />
              </Provider>
            </>
          )}
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
