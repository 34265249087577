import styled from '@emotion/styled';
import { Button, Grid, Stack, Typography } from '@mui/material';
import React from 'react';

function MobileBanner() {
  const CustomButton = styled(Button)({
    // height: 60,
    background: '#65B492',
    color: '#FFFFFF',
    borderRadius: '0px',
    fontSize: '15.084px',
    fontWeight: 'bold',
    width: '100%',
    boxShadow: 'none',
    '&:hover': {
      background: '#65B492',
      color: '#FFFFFF',
    },
  });

  const CustomButton2 = styled(Button)({
    // height: 60,
    background: '#34708C',
    color: '#FFFFFF',
    borderRadius: '0px',
    fontSize: '15.084px',
    fontWeight: 'bold',
    width: '50%',
    boxShadow: 'none',
    '&:hover': {
      background: '#34708C',
      color: '#FFFFFF',
    },
  });

  <a href="https://mxtradings.in/" target="_blank" rel="noreferrer">
    <CustomButton2 type="submit" variant="contained" className="join-ib-button">
      Login
    </CustomButton2>
  </a>;
  return (
    <div>
      <Stack sx={{ backgroundColor: '#FFFFFF' }}>
        <Stack>
          <img src={'/assets/images/Home/mobileBanner.jpg'} alt="" />
        </Stack>
        <Stack px={5} py={2}>
          <Typography>
            <span className="home-page-mobile-banner-text1">
              Exponential
              <br />{' '}
            </span>
            <span className="home-page-mobile-banner-text2"> Business</span>
            <span className="home-page-mobile-banner-text1"> Dreams,</span>{' '}
            <span className="home-page-mobile-banner-text1">Endless</span>{' '}
            <span className="home-page-mobile-banner-text2">Mutual</span>{' '}
            <span className="home-page-mobile-banner-text1">Possibilities!</span>
          </Typography>
        </Stack>
        <Grid display={'flex'} alignItems={'center'} justifyContent={'center'} ml={'-15px'}>
          <Stack sx={{ flexGrow: 0 }} py={0}>
            <a href=" https://mxtradings.in/investor/register/" target="_blank" rel="noreferrer">
              <CustomButton type="submit" variant="contained" className="join-ib-button">
                Join IB Club Today!
              </CustomButton>
            </a>
          </Stack>
          <Stack sx={{ flexGrow: 0, mx: 1 }} alignItems={''} justifyContent={''}>
            <a href="https://mxtradings.in/" target="_blank" rel="noreferrer">
              <CustomButton2 type="submit" variant="contained" className="join-ib-button">
                Login
              </CustomButton2>
            </a>
          </Stack>
        </Grid>
      </Stack>
    </div>
  );
}

export default MobileBanner;
