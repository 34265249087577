import React from 'react';
import { Box, Grid, Link, Stack, Typography } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// Import icons from Material-UI Icons
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { eventSpecification } from '../../../utils/projectDetails';
import { useDevice } from '../../../hooks/useResponsive';
import Shadow from '../../../components/Shadow';

export default function EventCarousel() {
  const { isMobile } = useDevice();

  // Custom left arrow component
  const CustomPrevArrow = (props) => {
    // eslint-disable-next-line react/prop-types
    const { onClick } = props;
    return (
      <div
        className="arrow arrow-left LeftArrow"
        role="button"
        tabIndex="0"
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onClick();
        }}
      >
        <ChevronLeftIcon />
      </div>
    );
  };

  // Custom right arrow component
  const CustomNextArrow = (props) => {
    // eslint-disable-next-line react/prop-types
    const { onClick } = props;
    return (
      <div
        className="arrow arrow-right RighrArrow"
        role="button"
        tabIndex="0"
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onClick();
        }}
      >
        <ChevronRightIcon />
      </div>
    );
  };

  // Configuration for the carousel
  const carouselSettings = {
    height: 250,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    slideMargin: 20,
  };

  const carouselSettingsMobile = {
    height: 100,
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <Stack position={'relative'} px={isMobile ? '' : 12} my={isMobile ? 1 : 5} mt={3}>
      {!isMobile && (
        <Shadow
          type="left"
          style={{
            top: '-4rem',
            width: 500,
          }}
        />
      )}
      <Grid>
        <Box sx={{ textAlign: '', pt: 2, mb: isMobile ? 0 : 2 }} py={isMobile ? 2 : 1} px={isMobile && 5.5}>
          <Typography className="swiper-headline" letterSpacing={3}>
            PREVIOUS EVENTS
          </Typography>
        </Box>
      </Grid>
      {isMobile ? (
        <Slider {...carouselSettingsMobile}>
          {eventSpecification?.map((item, key) => (
            <Stack key={key} className="">
              <Link href={item?.id}>
                <img
                  className=""
                  src={item?.img2}
                  alt="Event Slide 1"
                  style={{
                    objectFit: 'cover',
                    height: '200px',
                    width: '90%',
                    margin: 'auto',
                    borderRadius: 20,
                    outline: 'none',
                  }}
                />
              </Link>
            </Stack>
          ))}
        </Slider>
      ) : (
        <Slider {...carouselSettings}>
          {eventSpecification.map((item, key) => (
            <Stack key={key} sx={{ border: 'none' }} className="image-big-carousal">
              <Link href={`/event-detail/${item?.id}`}>
                <img
                  className=""
                  src={item?.img1}
                  alt="Event Slide 1"
                  style={{
                    objectFit: 'cover',
                    width: '97%',
                    height: 300,
                    borderRadius: 20,
                    outline: 'none',
                  }}
                />
              </Link>
            </Stack>
          ))}
        </Slider>
      )}
    </Stack>
  );
}
