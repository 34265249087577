import { Stack, Typography } from '@mui/material';
import React from 'react';
import VideoPlayer from '../../segment/@common/Video/VideoPlayer';
import { useDevice } from '../../hooks/useResponsive';

function ClubActivity() {
  const isMobile = useDevice()?.isMobile;
  return (
    <Stack>
      <Stack
        display={'flex'}
        alignItems={isMobile ? 'start' : 'center'}
        justifyContent={isMobile ? 'start' : 'center'}
        p={isMobile ? 3 : 5}
        pb={1}
        mt={isMobile ? 0 : 4}
        mx={0}
        mb={isMobile ? 0 : 3}
      >
        <Typography className="subtitle-1">CLUB ACTIVITIES</Typography>
      </Stack>
      <Stack mb={isMobile ? 3 : 6}>
        <VideoPlayer videoId={'eqy2MpY2soc'} />
      </Stack>{' '}
    </Stack>
  );
}

export default ClubActivity;
