import { Container, Stack } from '@mui/material';
import React from 'react';
// eslint-disable-next-line import/no-unresolved
import { MetaHelmet } from 'layouts/Helmet/Helmet';
import EventBanner from './eventBanner';
import Images from './images';
import { useDevice } from '../../hooks/useResponsive';
import Shadow from '../../components/Shadow';

export default function EventDetail() {
  const isMobile = useDevice()?.isMobile;
  return (
    <>
      <MetaHelmet title={'Event Detail'} />
      <Stack sx={{ backgroundColor: '#FFFFFF' }}>
        {isMobile ? (
          <div style={{ position: 'relative' }}>
            <EventBanner />
            <Images />
            <Shadow
              type="right"
              style={{
                bottom: '-5rem',
              }}
              isBottom
            />
          </div>
        ) : (
          <Container>
            <EventBanner />
            <Images />
          </Container>
        )}
      </Stack>
    </>
  );
}
