import React, { useState } from 'react';
import { Box, Grid, Link, Stack, TextField, Typography } from '@mui/material';
import TextMaxLine from '../../components/text-max-line';
import { useDevice } from '../../hooks/useResponsive';
import Shadow from '../../components/Shadow';
import { specificationsPoints } from '../../utils/projectDetails';

function Projects() {
  const isMobile = useDevice()?.isMobile;

  const [searchInput, setSearchInput] = useState('');
  const [filteredProjects, setFilteredProjects] = useState(specificationsPoints);

  const handleSearch = (e) => {
    const inputValue = e.target.value;
    setSearchInput(inputValue);

    const filtered = specificationsPoints.filter((project) =>
      project.text.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredProjects(filtered);
  };

  return (
    <Grid position={'relative'}>
      {isMobile && (
        <Stack sx={{ px: 1 }}>
          <Shadow
            type="left"
            style={{
              bottom: '0',
            }}
            isBottom
          />
          <TextField
            sx={{ backgroundColor: '#EDF8F4', borderRadius: 0, color: '#34708C' }}
            placeholder="Search Projects.."
            className="search-bar"
            onChange={handleSearch}
            value={searchInput}
          />
          <img
            src={'/assets/images/common/searchicon.png'}
            style={{ height: 20, width: 20, position: 'relative', bottom: 36, left: '90%', border: '0px' }}
            alt=""
          />
          {filteredProjects?.length <= 0 && (
            <Box>
              <Typography
                variant="body2"
                sx={{ width: isMobile ? '85%' : '60%', margin: 'auto', textAlign: 'center', my: 10 }}
              >
                No project matching with this name.
              </Typography>
            </Box>
          )}
        </Stack>
      )}

      <Grid container>
        {filteredProjects?.map((item, key) => (
          <Grid zIndex={2} item md={6} lg={6} sm={12} key={key} py={3}>
            <Grid className="image-box-home" mx={2}>
              <Stack>
                <img
                  src={item?.img || '/assets/images/Home/miniprojetcs.png'}
                  alt=""
                  style={{
                    objectFit: 'contain',
                    height: 'auto',
                    objectPosition: 'center',
                    // objectPosition: '0px -30px',
                    // height: '80%',
                    borderRadius: 15,
                  }}
                />
              </Stack>
              <Stack py={3} px={3}>
                {/* <Typography className="mini-card-text">{item?.text}</Typography> */}
                <TextMaxLine line={4}>{item?.text}</TextMaxLine>
                <Link href={`/project-detail/${item?.id}`} sx={{ textDecoration: 'none' }}>
                  <Stack className="view-project-home" my={2}>
                    View This Projects
                  </Stack>
                </Link>
              </Stack>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default Projects;
