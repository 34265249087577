import React from 'react';
import { Container, Grid, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDevice } from '../../hooks/useResponsive';
import { eventSpecification } from '../../utils/projectDetails';

function Images() {
  const isMobile = useDevice()?.isMobile;
  const params = useParams();
  const eventId = params?.id;

  const handleFilteredData = () => {
    const newData = eventSpecification?.filter((item) => item?.id === eventId);
    return newData;
  };

  return (
    <Container>
      <Stack py={isMobile ? 2 : 5}>
        {handleFilteredData()?.map((item, index) => (
          <>
            <Grid container py={3} key={index} zIndex={2}>
              <Grid item md={6} lg={6} sm={12} py={isMobile && 2}>
                <Stack px={isMobile ? 1 : 2}>
                  <img src={item?.img1} alt="" style={{ objectFit: 'contain', borderRadius: 15 }} />
                </Stack>
              </Grid>
              <Grid item md={6} lg={6} sm={12}>
                <Stack px={isMobile ? 1 : 2}>
                  <img src={item?.img2} alt="" style={{ objectFit: 'contain', borderRadius: 15 }} />
                </Stack>
              </Grid>
            </Grid>
            {!isMobile && (
              <Grid container>
                <Grid item md={6} lg={6} sm={12}>
                  <Stack px={2}>
                    <img src={item?.img3} alt="" style={{ objectFit: 'contain', borderRadius: 15 }} />
                  </Stack>
                </Grid>
                <Grid item md={6} lg={6} sm={12}>
                  <Stack px={2}>
                    <img src={item?.img4} alt="" style={{ objectFit: 'contain', borderRadius: 15 }} />
                  </Stack>
                </Grid>
              </Grid>
            )}
          </>
        ))}
      </Stack>
    </Container>
  );
}

export default Images;
