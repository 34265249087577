/* eslint-disable import/order */
import { Box, Grid } from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import { useDevice } from '../../../hooks/useResponsive';
// eslint-disable-next-line import/no-unresolved
import React from 'react';
import YouTube from 'react-youtube';
import PropTypes from 'prop-types';

function VideoPlayer({ videoId, height, width }) {
  const isMobiles = useDevice();
  const isMobile = isMobiles?.isMobile;

  console.log(isMobile?.isMobile);

  const opts = {
    width: isMobile ? '350px' : width || '690',
    height: isMobile ? '210px' : height || '315px',
    playerVars: {
      autoplay: 5,
      modestbranding: 1,
      showinfo: 0,
      controls: 0,
      rel: 0,
      fs: 0,
      iv_load_policy: 3,
      disablekb: 1,
    },
  };

  const onReady = (event) => {
    event.target.pauseVideo();
  };
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 0,
      }}
    >
      <Grid className="react-player" sx={{ p: 0 }}>
        <YouTube
          videoId={videoId || 'Gasyq3C2Qio'}
          className=" react-player-video"
          containerClassName=""
          opts={opts}
          onReady={onReady}
        />
      </Grid>
    </Box>
  );
}

export default VideoPlayer;

VideoPlayer.propTypes = {
  videoId: PropTypes.object,
  height: PropTypes.number,
  width: PropTypes.number,
};
